import React from "react";

export default function IkkeFunnet(): JSX.Element {
  return (
    <div className="flex h-screen">
      <div className="flex gap-1 m-auto">
        <div className="w-2.5 h-2.5 bg-current rounded-full animate-bounce"></div>
        <div className="w-2.5 h-2.5 bg-current rounded-full animate-bounce200"></div>
        <div className="w-2.5 h-2.5 bg-current rounded-full animate-bounce400"></div>
      </div>
    </div>
  );
}
