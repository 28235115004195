import loadable from "@loadable/component";
import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Laster from "./components/Laster";
import "./index.css";

const Hjem = loadable(() => import("./components/Hjem"));
const Tjeneste = loadable(() => import("./components/Tjeneste"));
const IkkeFunnet = loadable(() => import("./components/IkkeFunnet"));

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<Hjem fallback={<Laster />} />} />
      <Route path="/tjenester/:tjeneste" element={<Tjeneste fallback={<Laster />} />} />
      <Route path="*" element={<IkkeFunnet fallback={<Laster />} />} />
    </Routes>
  </Router>,
  document.getElementById("root"),
);
